import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useDialogStyles } from './styles'

type Props = {
  title: string
  body: string
  requiredText?: string
  cleanup: () => void
  resolve: (value: boolean) => void
}

const ConfirmModal: React.FC<Props> = (props) => {
  const { title, body, requiredText } = props
  const classes = useDialogStyles()
  const [open, setOpen] = useState(true)
  const [enableOK, setEnableOk] = useState(!props.requiredText)

  const reqTextChanged: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    setEnableOk(ev.target.value === props.requiredText)
  }

  const handleModalClose = () => {
    setOpen(false)
  }

  const confirm = () => {
    props.resolve(true)
    props.cleanup()
  }

  const cancel = () => {
    props.resolve(false)
    props.cleanup()
  }

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.body}>{body}</DialogContentText>
        {requiredText && <TextField placeholder={requiredText} onChange={reqTextChanged}/>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={cancel}>
          キャンセル
        </Button>
        <Button variant="contained" color="primary" onClick={confirm} disabled={!enableOK}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * 非同期 確認ダイアログ表示
 * @param title ダイアログタイトル
 * @param body 内容
 * @param requiredText 入力必須
 * @returns キャンセル/OK
 */
export const confirm = (title: string, body: string, requiredText: string | undefined = undefined): Promise<boolean> => {
  const wrapper = document.body.appendChild(document.createElement('div'))
  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper)
    return setTimeout(() => wrapper.remove())
  }
  const promise = new Promise<boolean>((resolve, reject) => {
    try {
      ReactDOM.render(
        <ConfirmModal
          cleanup={cleanup}
          resolve={resolve}
          {...{ title, body, requiredText }}
        />,
        wrapper,
      )
    } catch (e) {
      cleanup()
      reject(e)
      throw e
    }
  })
  return promise
}
