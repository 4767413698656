import React from 'react'
import type { GridColDef } from '@material-ui/data-grid'
import TableComponent from '../components/TableComponent'
import { Checkbox, createStyles, makeStyles } from '@material-ui/core'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { selectTenants } from '../features/tenants/tenantsSlice'
import { AddFaceRequest } from '../features/faces/facesType'
import { addFaceAsync, deleteFaceAsync, selectFaceImageKeys, selectFaces, selectFacesStatus } from '../features/faces/facesSlice'
import { confirm } from '../features/dialogs/confirm'
import SsecImg from '../components/SsecImg'
import { cellTimeGetter, max50Validator } from '../features/utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    faceImage: {
      maxWidth: 100,
      maxHeight: 100,
    }
  }),
)

const FacePage: React.FC = () => {
  const tenants = useAppSelector(selectTenants)
  const faces = useAppSelector(selectFaces)
  const facesStatus = useAppSelector(selectFacesStatus)
  const faceKeys = useAppSelector(selectFaceImageKeys)
  const dispatch = useAppDispatch()

  const classes = useStyles()

  const cols: GridColDef[] = [
    {
      field: 'name',
      headerName: '氏名',
      width: 150,
    },
    { field: 'myFaceId', headerName: '人ID', minWidth: 300 },
    { field: 'myFaceImageId', headerName: '顔画像ID', minWidth: 50 },
    {
      field: 'tenantId',
      headerName: 'テナントID',
      width: 200,
    },
    {
      field: 'faceUrl',
      headerName: '顔画像1',
      //minWidth: 120,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell({ row, value }) {
        return  <div>
                <SsecImg src={row.faceUrl as string} ssecKey={faceKeys[row.tenantId]} className={classes.faceImage} alt="" />
                <SsecImg src={ row.images.length >=1 ? (row.images[0].faceUrl as string) : "" }
                   ssecKey={faceKeys[row.tenantId]} className={classes.faceImage} alt="" />
                </div>
      }
    },
    {
      field: 'faceUrl2',
      headerName: '顔画像2',
      //minWidth: 120,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell({ row, value }) {
        return  <div>
                <SsecImg src={ row.images.length >=2 ? (row.images[1].faceUrl as string) : "" }
                   ssecKey={faceKeys[row.tenantId]} className={classes.faceImage} alt="" />   
                </div>
      }
    },
    {
      field: 'faceUrl3',
      headerName: '顔画像3',
      //minWidth: 120,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell({ row, value }) {
        return  <div>
                <SsecImg src={ row.images.length >=3 ? (row.images[2].faceUrl as string) : "" }
                   ssecKey={faceKeys[row.tenantId]} className={classes.faceImage} alt="" />   
                </div>
      }
    },
    {
      field: 'faceUrl4',
      headerName: '顔画像4',
      //minWidth: 120,
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell({ row, value }) {
        return  <div>
                <SsecImg src={ row.images.length >=4 ? (row.images[3].faceUrl as string) : "" }
                   ssecKey={faceKeys[row.tenantId]} className={classes.faceImage} alt="" />   
                </div>
      }
    },


    {
      field: 'blacklist',
      headerName: 'ブラックリスト',
      width: 140,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell({ value }) {
        return <Checkbox readOnly color="primary" checked={value === 1} />
      },
    },
    {
      field: 'createAt',
      headerName: '作成日時',
      minWidth: 160,
      valueGetter: cellTimeGetter,
    },
    // {
    //   field: 'notify',
    //   headerName: '通知',
    //   width: 80,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell({ value }) {
    //     return <Checkbox readOnly color="primary" checked={value === 1} />
    //   },
    // },
  ]

  return (
    <>
      <TableComponent
        loading={facesStatus === 'loading'}
        createForm={{
          formTitle: '顔画像登録',
          FormRows: [
            {
              type: 'select',
              name: 'tenantId',
              label: 'テナント',
              items: tenants.map(({ id, name }) => ({ name, value: id })),
              //items: tenants,
            },
            {
              type: 'select',
              name: 'version',
              label: '顔認証バージョン',
              items: ["2.x", "4.x"],
              value: "4.x",

            },
            {
              type: 'checkbox',
              name: 'isAddImage',
              hidden(values) {
                if (values['version'] === "2.x"){
                  return true
                } else if (values['version'] !== "4.x"){
                  return true
                }
                return false
              },
              label: '「人」に画像を追加',
            },
            {
              type: 'select',
              name: 'faceName',
              label: '画像を追加する人',
               items: faces,
              itemGetter: ({ myFaceId, name }) => ({
                name: name,
                value: myFaceId,
              }),
              filter(values, items) {
                return items.filter((i) => ((i.tenantId === values['tenantId']) && (i.images.length !== 0)))
              },
              hidden(values) {
                if (values['version'] !== "4.x"){
                  return true
                }
                if (values['isAddImage'] === false){
                  return true
                }
                return false
               },
            },            
            {
              name: 'name',
              label: '名前',
              value: '',
              hidden(values) {
                if (values['version'] === "2.x"){
                  return false
                }
                if (values['isAddImage'] === true){
                  return true
                }
                return false
               },
              validators: [
                max50Validator
              ],
            },
            {
              type: 'checkbox',
              name: 'blacklist',
              label: 'ブラックリスト',
              hidden(values) {
                if (values['isAddImage'] === true){
                  return true
                }
                return false
               },
            },
            // {
            //   type: 'checkbox',
            //   name: 'notify',
            //   label: '通知',
            // },
            {
              type: 'image',
              name: 'image',
              label: '画像',
              accept: 'image/jpeg',
            },
          ],
        }}
        
        onSubmit={async (values) => {
          if (values['version'] === '2.x') {
            const face = {
              name: values['name'],
              notify: 1,
              myFaceId: '',
              blacklist: values['blacklist'] ? 1 : 0,
              upload_file: values['image'],
              faceVersion: values['version'],
            } as AddFaceRequest
            
            dispatch(addFaceAsync([face, values['tenantId'] as string]))
            //dispatch(addFaceAsync([face, values['tenant'] as string]))
          }else{
            const tmpMyFaceId = ((values['name'] as string).length === 0 ?  values['faceName'] : "")
            const tmpFaces = faces.find((f) => (f.myFaceId === tmpMyFaceId))

            let tmpName = ""
            if ((values['isAddImage'] === true) && (tmpFaces !== null) && (tmpFaces !== undefined)){
              tmpName = tmpFaces.name
            }else{
              tmpName = values['name'] as string
            }

            const face = {
              //name: values['name'],
              //faceId: tmpName.length == 0 ?  values['faceName'] : "" ,
              //myFaceId: (values['name'] as string).length === 0 ?  values['faceName'] : "" ,
              name: tmpName,
              myFaceId: tmpMyFaceId,
              notify: 1,
              blacklist: values['blacklist'] ? 1 : 0,
              upload_file: values['image'],
              faceVersion: values['version'],
            } as AddFaceRequest
            dispatch(addFaceAsync([face, values['tenantId'] as string]))
            //dispatch(addFaceAsync([face, values['tenant'] as string]))
          }
          return true
        }}

        onDelete={async ([myFaceId]) => {
          const face = faces.find((f) => (f.myFaceId === myFaceId))
          const tenant = tenants.find((t) => t.id === face?.tenantId)
          if(face && tenant &&
            await confirm('顔認証情報削除',
              `本当に${tenant.name} / ${face.name} さんの顔認証情報を削除してもよろしいですか?`)) {

                let tmpFaceImageId = "none" // 旧バージョンの場合はmyFaceImageIdをnoneとする。
                if (face.images.length !== 0) {
                  tmpFaceImageId = face.images[0].myFaceImageId
                }

            dispatch(deleteFaceAsync([face.myFaceId, tmpFaceImageId, tenant.id]))
          }
        }}
        /*
        onDelete={async ([myFaceId]) => {
          const face = faces.find((f) => (f.myFaceId === myFaceId) && (f.myFaceImageId === myFaceImageId))
          const tenant = tenants.find((t) => t.id === face?.tenantId)
          if(face && tenant &&
            await confirm('顔認証情報削除',
              `本当に${tenant.name} / ${face.name} さんの顔認証情報を削除してもよろしいですか?`)) {

            dispatch(deleteFaceAsync([face.myFaceId, face.myFaceImageId, tenant.id]))
          }
        }}
        */
        getRowId={(row) => {
          return row.myFaceId
        }}
        rows={faces}
        columns={cols}
        rowHeight={100}
        pageSize={10}
      />
    </>
  )
}

export default FacePage
